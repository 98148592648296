import React from 'react';

const StickyHeader = props => {
  return (
    <div className="header sticky-top">
      <div>
        {' '}
        <h1 className={`title ${props.titleClass ? props.titleClass : ' '}`}>
          <i className={props.icon} />
          {props.titulo}
        </h1>
      </div>
    </div>
  );
};

export default StickyHeader;
