import React from 'react';
import { useEffect, useState } from 'react';
import './IssueFilters.css';
import SelectIssueEnvironment from '../common/selects/SelectIssueEnvironment';
import SelectIssueUrgent from '../common/selects/SelectIssueUrgent';
import SelectIssueType from '../common/selects/SelectIssueType';
import SelectIssueMilestone from '../common/selects/SelectIssueMilestone';
import SelectIssueAttention from '../common/selects/SelectIssueAttention';

const INIT_STATE = {
  title: null,
  number: null,
  issueType: null,
};

const SprintIssuesFilters = ({ filters, setFilters }) => {
  const onFiltersChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setFilters({ ...filters, [name]: value });
  };

  const onFiltersRefresh = () => {
    setFilters(INIT_STATE);
  };

  return (
    <>
      <div className="row transactions-filters">
        <div className="col-lg-4 col-sm-6">
          <input
            type="text"
            className="form-control"
            placeholder="Título de la tarea"
            value={filters.title ? filters.title : ''}
            name={'title'}
            onChange={onFiltersChange}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <input
            type="text"
            className="form-control"
            placeholder="Número de la tarea"
            value={filters.number ? filters.number : ''}
            name={'number'}
            onChange={onFiltersChange}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <SelectIssueType
            value={filters.issueType}
            name={'issueType'}
            placeholder="Tipo de tarea"
            onChange={onFiltersChange}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="text-right">
            <button
              type="button"
              className="btn butt btn-warning w-100"
              onClick={onFiltersRefresh.bind(this)}
              style={{ marginLeft: '2px' }}>
              Reiniciar filtros <i className="fas fa-sync"></i>
            </button>
          </div>
        </div>

        {/* <div className="col-lg-2 col-sm-6">
          <SelectIssueUrgent
            value={state.urgent}
            placeholder="¿Es urgente?"
            name={'urgent'}
            onChange={onFiltersChange}
           
          />
        </div>
        <div className="col-lg-2 col-sm-6">
          <SelectIssueAttention
            value={state.attention}
            placeholder="¿Requiere atención?"
            name={'attention'}
            onChange={onFiltersChange.bind(this)}
            
          />
        </div> */}
      </div>
      {/* <div className="row transactions-filters">
        <div className="col-lg-4 col-sm-6">
          <div className="text-right">
            <button
              type="button"
              className="btn butt btn-warning w-100"
              onClick={onFiltersRefresh.bind(this)}
              style={{ marginLeft: '2px' }}>
              Reiniciar filtros <i className="fas fa-sync"></i>
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default SprintIssuesFilters;
