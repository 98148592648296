import React from 'react';
import ItemPending from './ItemPending';
import { Droppable } from 'react-beautiful-dnd';
import { styled } from '../../../stitches.config';
import Tooltip from '../../common/Tooltip';
import apiHelpers from '../../../utils/apiHelpers';
import { Link } from 'react-router-dom';

const ColumnPending = props => {
  const StyledColumn = styled('div', {
    padding: '24px 0',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8,
    h2: {
      margin: 0,
      padding: '0 16px',
      textAlign: 'center',
    },
    width: '300px',
    minWidth: '300px',
  });

  const StyledList = styled('div', {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: 8,
  });

  return (
    props.column.id && (
      <Droppable droppableId={props.column.id.toString()}>
        {provided => (
          <StyledColumn>
            <Link to={`${process.env.PUBLIC_URL}/project/${props.column.name}`}>
              <h2
                className={'title ' + props.onboarding}
                data-tip
                data-for={'Tooltip-' + props.column.name.replace(/\s/g, '')}>
                {props.column.name.slice(0, 15)} ({props.column.issues.length})
              </h2>
            </Link>

            <Tooltip
              id={'Tooltip-' + props.column.name.replace(/\s/g, '')}
              tooltipText={`${
                apiHelpers.isQA
                  ? `Tareas de ${props.column.name} en QA`
                  : `Tareas de ${props.column.name} en Pendientes`
              }`}
            />
            <StyledList {...provided.droppableProps} ref={provided.innerRef}>
              {props.column.issues.map((issue, index) => (
                <ItemPending
                  key={issue.id.toString()}
                  project={props.project}
                  column={props.column.name}
                  issue={issue}
                  index={index}
                />
              ))}
              {provided.placeholder}
            </StyledList>
          </StyledColumn>
        )}
      </Droppable>
    )
  );
};

export default ColumnPending;
