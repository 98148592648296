import React from 'react';
import { Link } from 'react-router-dom';
import './BreadCrum.css';

export const BreadCrum = props => {
  return (
    <nav className="d-inline-block fixed" aria-label="breadcrumb">
      <ol className="breadcrumb">
        <i className={props.icon}></i>
        <li className="breadcrumb-item pl-2">
          <Link to={`${process.env.PUBLIC_URL}/project/${props.project}`}>
            {props.back}
          </Link>
        </li>
        <li
          className={`breadcrumb-item active ${
            props.titleClass ? props.titleClass : ' '
          }`}
          aria-current="page">
          {props.actual}
        </li>
      </ol>
    </nav>
  );
};

export const BreadCrumId = props => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <i className={props.icon}></i>
        <li className="breadcrumb-item pl-2">
          <Link to={`${process.env.PUBLIC_URL}/${props.url}`}>
            {props.backBread}
          </Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {props.actual}
        </li>
      </ol>
    </nav>
  );
};
